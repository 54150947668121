import React from "react";
import bannerLogo from './images/banner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import footerlogo from './images/logo-footer.png';
import Discord from './images/discord.png';
import Twitter from './images/twitter.png';
import OpenSea from './images/opensea.png';
import { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import logo from './images/logo-head.png';
import {
  connectWallet,
  onDisconnect,
  mintFunction,
  loadStatus,
  loadTotalSupply,
  loadMaxMintAmount,
  loadCost,
  getCurrentWalletConnected,
  CheckWalletAddress,
  fetchAccountData
} from "./util/interact.js";



const MainPage = () => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [WAuthorized, setWAuthorized] = useState("");
  const [MintAmount, setMintAmount] = useState("2");
  const [cost, setCost] = useState(0);

  const [CStatus, setCStatus] = useState(false);
  const [TotalSupply, setTotalSupply] = useState(0);
  const [MaxMintAmount, setMaxMintAmount] = useState(1);
  const [MaxSupply, setMaxSupply] = useState(300);
  useEffect(async () => {
    const Cost = await loadCost();
    setCost(Cost);
    
    const { address, status,WAuthorized,Provider } = await getCurrentWalletConnected();
     
    setWallet(address);
    setStatus(status);
    setWAuthorized(WAuthorized);
    addWalletListener(Provider);
    //background: rgba(76, 175, 80, 0.3) /* Green background with 30% opacity */

    const CStatus = await loadStatus();
    setCStatus(CStatus);
    const TotalSupply = await loadTotalSupply();
    setTotalSupply(TotalSupply);
    const MaxMintAmount = await loadMaxMintAmount();
    setMaxMintAmount(MaxMintAmount);
    // const meb = await loadMinEthBalance();
    // setMinEthbalance(meb);
    //addWalletListener();
  }, []);

  useEffect(async () => {
    //const timer = setTimeout(async () =>  { const TotalSupply2 = await  loadTotalSupply();setTotalSupply(TotalSupply2); }, 60e3)
    //return () => clearTimeout(timer)
   });

const  addWalletListener =  (prvdr) => {
  if(prvdr!==null && typeof(prvdr) !== typeof(undefined)){
       prvdr.on("accountsChanged", async(accounts) => {
        const r = await fetchAccountData();
        setWallet(r.address);
        setStatus(r.status);
        setWAuthorized(r.WAuthorized);
        if(r.address===""){
          await onDisconnect();
        }
      });
      prvdr.on("chainChanged", async (chainId) => {
        const r = await fetchAccountData();
        setWallet(r.address);
        setStatus(r.status);
        setWAuthorized(r.WAuthorized);
      });
      prvdr.on("disconnect", () => {
        onDisconnect();
      });
    }
  }
 function updateWallet(wa,wst,watz){
  setWallet(wa);
  setStatus(wst);
  setWAuthorized(watz);
}

  const connectWalletPressed = async () => {
    if(walletAddress === ""){
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
        setWAuthorized(walletResponse.WAuthorized);
        await addWalletListener(walletResponse.Provider);
      }
    else
    {
      await onDisconnect();
      setStatus("");
      setWallet("");
      setWAuthorized("");
    }
  };

  
  const onMintPressed = async () => {
    setStatus('') ;
    const { status } = await mintFunction(walletAddress, MintAmount);
     setStatus(status);
     if(status === "Please connect your wallet using the top right button"){
      await connectWalletPressed();
     }
  };

  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      //setCStatus(false);
      return <span></span>;
    } else {
      return <div className="">
        <div className="divCountDown">
        <div><span className="divCountDownNumber">{days}</span><br/>Days</div>
        <div><span className="divCountDownNumber">{hours}</span> <br/>Hours</div>
        <div><span className="divCountDownNumber">{minutes}</span> <br/>Minutes</div>
        <div><span className="divCountDownNumber">{seconds}</span> <br/>Seconds</div>
      </div>
      </div>
      //<Countdown date={Date.now() + ((new Date(2022, 2, 18, 20, 0, 0)).getTime() - new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()).getTime() )} renderer={renderer} />
    }
  };

 
  return (
    <div>
      <div className='main-body'>
     <header>
      <div className="container container-sm">
        <div className="row align-items-center">
          <div className="col-6">
            <div className="nabvar-brand">
              <a href="/">
                <img src={logo} alt="logo"/>
                <span>&nbsp;&nbsp;&nbsp;Wen Lambo</span>
              </a>
            </div>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-transparent" onClick={connectWalletPressed} title={walletAddress =="" ? "" : "Click to disconnect"}>
               {walletAddress.length > 0 ? (
                  "Connected: " +
                    String(walletAddress).substring(0, 6) +
                    "..." +
                    String(walletAddress).substring(38)
                  ) : (
                    <span>CONNECT WALLET</span>
                  )
                  }
            </button>
          </div>
        </div>
      </div>
    </header>
    
    <div className="buy-nft">
      <div className="container container-sm">
        <div className="row">
          <div className="col-md-6">
            <div className="nft-img-sec">
              <img src={bannerLogo} alt="nft" className="img-fluid"/>
              {/* <iframe src="https://player.vimeo.com/video/756776983?h=9618378aa5" width="320px" height="180px" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="buyDesc">
              <ul className="f-figures">
                <li>
                  <p>STATUS</p>
                  <p>{CStatus ? <p>BATCH 1 MINTING</p>  : <p>SOLD OUT / <a style={{color:"#000"}} href="https://opensea.io/collection/wenlambo-wlb" target={`_blank`}>OPENSEA</a></p>}</p>
                </li>
                <li>
                  <p>NETWORK</p>
                  <p><p>{process.env.REACT_APP_Dev_Mode ==1 ? process.env.REACT_APP_Test_Network_Name : process.env.REACT_APP_Main_Network_Name}</p></p>
                </li>
                <li>
                  <p>PRICE</p>
                  <p><p>{cost / 1000000000000000000} ETH</p></p>
                </li>
              </ul>
              <div className="buyCalculator">
                <p>{TotalSupply > MaxSupply ? 0 : MaxSupply - TotalSupply} Remaining</p>
                <div className="progress mb-3">
                  <div className="progress-bar" role="progressbar" style={ {width: `${TotalSupply / MaxSupply * 100}%` }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p className="dim">How many NFT's to mint?</p>
                <div className="buyCounter">
                  <button onClick={ (e) => { document.getElementById('txtNumber').stepDown(); setMintAmount(document.getElementById('txtNumber').value);} }><FontAwesomeIcon icon={faMinus} /></button>
                  <input autoFocus={true} type="number" min="1" defaultValue={2} max={MaxMintAmount} name='txtNumber' id="txtNumber" readOnly onChange={(e) => setMintAmount(e.target.value)} />
                  <button onClick={ (e) => { document.getElementById('txtNumber').stepUp() ;setMintAmount(document.getElementById('txtNumber').value);} }><FontAwesomeIcon icon={faPlus} /></button>                  
                </div>
                <div className="d-grid gap-2">
                  <Button variant="yellow" onClick={onMintPressed}>MINT NOW</Button>
                </div>
              </div>
              <p id="status">{status}</p>
            </div>
            
            <div className="divFreeMint">
                                      <label className="field-label">FREE BONUSES INCLUDING WITH THIS MINT:</label><br/>
                                      ✅ FREE MINT: Medium Public Land Plot(valued 0.09 ETH)<br/>
                                      ✅ FREE MINT: Meta Garage NFT<br/>
                                      ✅ FREE MINT: Race to win ETH (1st, 2, 3 prize pools)<br/>
                                     </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <footer>
      <div className="container container-sm">
        <div className="row align-items-center">
          <div className="col-md-4 order-md-0 order-2">
            <p>© 2023, Wen Lambo</p>
          </div>
          <div className="col-md-4 order-md-1 order-0">
            <a href="#" className="footer-logo"><img src={footerlogo} alt="logo"/></a>
          </div>
          <div className="col-md-4 order-md-2 order-1">
            <ul className="social-media-list">
              <li><a href="https://discord.gg/zfkPDKbW7w" target="_blank"><img src={Discord} alt="logo"/></a></li>
              <li><a href="https://twitter.com/Wen_Lambo_LFG" target="_blank"><img src={Twitter} alt="logo"/></a></li>
              <li><a href="https://opensea.io/collection/wenlambo-wlb" target="_blank"><img style={{width:'30px',backgroundColor:'#000'}} src={OpenSea} alt="logo"/></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    </div>
  );
};

export default MainPage;

