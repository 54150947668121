import MainPage from './Main'
import './App.scss';

function App() {
  return (
    <div >
    <MainPage></MainPage>
    </div>
  );
}

export default App;
